import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getListedProductVariants, updateInventory } from '../../../actions/productActions';
import { selectedMainMenu, onInventoryFilterOptions } from '../../../actions/uiAction';
import { getL10N } from "../../../helpers/middleware";
import { Row, Col, Select, Input } from 'antd';
import config from '../../../apiConfig';
import '../../../assets/sass/dsk/product.sass';
import SearchIcn from "../../../assets/images/search.svg";
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";
import { useNav } from '../../../helpers/customHook';
import Loading from '../common/Loading';
import VideoSvg from "../../../assets/images/media-icons/video.svg";
import AudioSvg from "../../../assets/images/media-icons/audio.svg";
import Unknown from "../../../assets/images/media-icons/unknown.svg";
import { getOutlets } from '../../../actions/outletAction';
// import VisibilityOnSvg from "../../../assets/images/visibility-on.svg";
// import VisibilityOffSvg from "../../../assets/images/visibility-off.svg";
import { motion } from "framer-motion";

const { Option } = Select;

const ProductInventory = (props: any) => {
    const windowHeight = window.innerHeight;
    const navigateTo = useNav();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const outlets = useSelector((state: any) => state.outlet.outlets);
    const { selectedStore, inventoryFilterOptions, selectedOuId } = useSelector((state: any) => state.ui);
    const [productVariantList, setProductVariantList] = useState(null);
    // const [selectedProductId, setSelectedProductId] = useState(null);
    const [productVariants, setProductVariants] = useState([]);
    const [selectedOutletId, setSelectedOutletId] = useState(null);
    const [search, setSearch] = useState("");

    // const [reservedId, setReservedId] = useState(null);
    // const [reserved, setReserved] = useState(null);
    // const reservedRef = useRef(null);
    // const [debounceReservedIdValue, setDebouncedReservedIdValue] = useState(reserved);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebouncedReservedIdValue(reserved);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [reserved]);

    // useEffect(() => {
    //     if (reservedId) {
    //         onUpdateVariantDetails('reserved', parseInt(reserved), reservedId);
    //     }
    //     //eslint-disable-next-line
    // }, [debounceReservedIdValue]);

    // const [onHandId, setOnHandId] = useState(null);
    // const [onHand, setOnHand] = useState(null);
    // const onHandRef = useRef(null);
    // const [debounceOnHandIdValue, setDebouncedOnHandIdValue] = useState(onHand);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebouncedOnHandIdValue(onHand);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [onHand]);

    // useEffect(() => {
    //     if (onHandId) {
    //         onUpdateVariantDetails('onHand', parseInt(onHand), onHandId);
    //     }
    //     //eslint-disable-next-line
    // }, [debounceOnHandIdValue]);


    // const [committedId, setCommittedId] = useState(null);
    // const [committed, setCommitted] = useState(null);
    // const committedRef = useRef(null);
    // const [debounceCommittedIdValue, setDebouncedCommittedIdValue] = useState(committed);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebouncedCommittedIdValue(committed);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [committed]);

    // useEffect(() => {
    //     if (committedId) {
    //         onUpdateVariantDetails('committed', parseInt(committed), committedId);
    //     }
    //     //eslint-disable-next-line
    // }, [debounceCommittedIdValue]);

    const [availableStockId, setAvailableStockId] = useState(null);
    const [availableStock, setAvailableStock] = useState(null);
    const availableStockRef = useRef(null);
    // const [debounceAvailableStockIdValue, setDebouncedAvailableStockIdValue] = useState(availableStock);

    useEffect(() => {
        if (availableStockId && availableStockRef && availableStockRef.current) {
            availableStockRef.current.focus();
        }
        //eslint-disable-next-line
    }, [availableStockRef && availableStockId]);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebouncedAvailableStockIdValue(availableStock);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [availableStock]);

    // useEffect(() => {
    //     if (availableStockId) {
    //         onUpdateVariantDetails('available', parseInt(availableStock), availableStockId);
    //     }
    //     //eslint-disable-next-line
    // }, [debounceAvailableStockIdValue]);

    const onUpdateVariantDetails = async (key: string, value: any, productVariantId: string) => {
        const currentRecord = productVariantList.filter(pv => pv.variantId === productVariantId && pv[key] !== value);
        if (currentRecord.length > 0 && productVariantId) {
            const payload = {
                "variants": [{
                    variantId: productVariantId,
                    [key]: value
                }]
            }
            const resp = await props.updateInventory(selectedOutletId, payload);
            if (resp.isUpdated) {
                setProductVariantList(productVariantList.map((pv) => {
                    if (pv.variantId === productVariantId) {
                        return {
                            ...pv,
                            available: value,
                            onhand: value + pv.committed + pv.reserved,
                        };
                    }
                    return pv;
                }));
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (selectedStore) {
                await getOutlets()(dispatch);
            }
        }
        fetchData();
        //eslint-disable-next-line
    }, [selectedStore]);

    useEffect(() => {
        // if (productVariantList == null) {
        onFilterProducts();
        // }
        //eslint-disable-next-line
    }, [productVariantList]);

    useEffect(() => {
        if (selectedOutletId) {
            loadProducts();
        }
        //eslint-disable-next-line
    }, [selectedOutletId]);

    // useEffect(() => {
    //     if (props.selectedOutlet !== selectedOutletId) {
    //         setSelectedOutletId(props.selectedOutlet);
    //     }
    //     //eslint-disable-next-line
    // }, [props.selectedOutlet]);

    useEffect(() => {
        const { query } = inventoryFilterOptions;
        if (outlets && outlets.length > 0) {
            if (query !== search) {
                setSearch(query);
            }
            if (selectedOuId && selectedOuId !== 'all') {
                setSelectedOutletId(selectedOuId);
            } else {
                setSelectedOutletId(outlets[0].id);
            }
            onFilterProducts();
        }
        //eslint-disable-next-line
    }, [outlets, inventoryFilterOptions, selectedOuId]);

    useEffect(() => {
        const handler = setTimeout(() => {
            props.onInventoryFilterOptions({ ouId: selectedOutletId, query: search });
        }, 500);
        return () => {
            clearTimeout(handler);
        };
        //eslint-disable-next-line
    }, [search, productVariantList])

    const loadProducts = async () => {
        setLoading(true);
        let resp = await props.getListedProductVariants(selectedOutletId);
        setLoading(false);
        setProductVariantList(resp);
    }

    // const isStockStatus = (record) => {
    //     let status = true;
    //     if (!loadOutletBasedProduct(record, "isAvailable", [selectedOutletId])) {
    //         status = false;
    //     }
    //     if (loadOutletBasedProduct(record, "outOfStock", [selectedOutletId])) {
    //         status = false;
    //     }
    //     return status;
    // }

    const onFilterProducts = () => {
        if (productVariantList) {
            let filterProducts = [];
            let isSearch = false;
            for (let p of productVariantList) {
                isSearch = false;
                if (search) {
                    let title = getL10N(p, "title", null).i10n;
                    if ((title && title.toLowerCase().indexOf(search.toLowerCase()) > -1) || p.sku?.toLowerCase().includes(search) || (p.subtitle && p.subtitle.toLowerCase().indexOf(search.toLowerCase()) > -1)) {
                        isSearch = true
                    }
                    const { committed, available, onhand } = p;
                    if (committed?.toString() === search) {
                        isSearch = true;
                    }
                    if (available?.toString() === search) {
                        isSearch = true;
                    }
                    if (onhand?.toString() === search) {
                        isSearch = true;
                    }
                }
                if (!search || search.trim().length === 0 || isSearch) {
                    filterProducts.push(p);
                }
            }
            setProductVariants(filterProducts);
        }
    }

    function getMediaType(input) {
        if (typeof input === 'string') {
            if (input.startsWith('data:')) {
                const mime = input.split(';')[0].split(':')[1];
                switch (mime) {
                    case 'image/jpg':
                    case 'image/jpeg':
                    case 'image/png':
                    case 'image/webp':
                    case 'image/svg':
                    case 'image/ico':
                    case 'image/gif':
                        return 'image';
                    case 'audio/mpeg':
                    case 'audio/mp3':
                    case 'audio/wav':
                    case 'audio/ogg':
                    case 'audio/midi':
                        return 'audio';
                    case 'video/mpeg':
                    case 'video/mp4':
                    case 'video/mov':
                    case 'video/webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            } else {
                const extension = input.split('.').pop().toLowerCase();
                switch (extension) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'webp':
                    case 'svg':
                    case 'ico':
                    case 'gif':
                        return 'image';
                    case 'mp3':
                    case 'wav':
                    case 'ogg':
                    case 'midi':
                        return 'audio';
                    case 'mp4':
                    case 'mov':
                    case 'mpeg':
                    case 'webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            }
        } else {
            return 'unknown';
        }
    }

    const onGenerateVariant = (options) => {
        let title = "";
        for (let op of Object.keys(options)) {
            title += ` ${title.length > 0 ? " / " : ""} ${options[op]}`;
        }
        return title;
    }

    return (
        <Row className="product">
            {/* <Col span={24}>
                    <div className="head">Products</div>
                </Col> */}
            {isLoading && <Loading background="#00000070"></Loading>}
            {(outlets && outlets.length > 0) && <Col className="list" style={{ height: windowHeight - 76 }}>
                <div className="action-bar" style={{ paddingLeft: 24, paddingRight: 12 }}>
                    {/* <div className="title">
                        You've got {productVariants && productVariants.length} productVariants listed
                    </div> */}
                    <div style={{ width: 396 }} >
                        <Input className="filter" name="search" value={search} onChange={(event) => {
                            setSearch(event.target.value)
                        }} placeholder="Search" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                    </div>
                    <div className="actions" style={{ justifyContent: 'flex-end' }}>
                        <Select
                            style={{ width: 240 }}
                            className="select"
                            // maxTagCount={2}
                            // mode="tags"
                            size="middle"
                            value={selectedOutletId}
                            defaultValue={selectedOutletId}
                            onChange={(value) => { setSelectedOutletId(value) }}
                        // style={{ maxHeight: "60px", overflowY: "auto", borderColor: 'rgb(199 200 214)', borderRadius: "1px", width: '100%' }}
                        >
                            {outlets && outlets.map((item) => {
                                return <Option key={item.id} style={{ paddingLeft: '16px' }} value={item.id}>
                                    <label htmlFor={item.id} style={{ paddingLeft: "16px" }}>{item.name}</label>
                                </Option>
                            })}
                        </Select>
                        {/* <button style={{ width: 300 }} className="sp_btn btn" onClick={() => onAction(null, "new")} >+ Add Product To Outlet</button> */}
                    </div>
                </div>
                <div className="content">
                    <Row className="header" style={{ marginLeft: 30 }}>
                        <Col span={10}>
                            Product Name
                        </Col>
                        <Col span={6}>
                            SKU
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            Reserved Stock
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            Committed Stock
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            Available Stock
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            On Hand Stock
                        </Col>
                        {/* <Col span={2} style={{ textAlign: 'center' }}>
                            Visibility
                        </Col> */}
                    </Row>
                    <Row className="contains" >
                        <Col span={24}>
                            {productVariants && productVariants.map((record, index) => {
                                let vAvailableStock = parseInt(record.available) || 0;
                                let vCommitted = parseInt(record.committed) || 0;
                                let vOnHand = parseInt(record.onhand) || 0;
                                let vReserved = parseInt(record.reserved) || 0;
                                // let vIsVisible = record.isVisible || false;
                                return <Row className="item" key={record.variantId} style={{ cursor: 'default' }}>
                                    <Col span={10}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ float: "left" }}>
                                                {/* <span className={loadOutletBasedProduct(record, "isAvailable") ? "active" : "inactive"}></span> */}
                                                {(!record.media || record.media.length === 0) && <img alt='Not Available' src={NoImageAvailableIcn} style={{
                                                    marginRight: "10px",
                                                    width: "50px",
                                                    borderRadius: "3px"
                                                }} />}

                                                {record.media && record.media.length > 0 && (
                                                    <div style={{ display: 'flex' }}>
                                                        {record.media && record.media.length > 0 && (
                                                            <div style={{ display: 'flex' }}>
                                                                {/* Render placeholder media based on media type of the first image */}
                                                                {getMediaType(record.media && record.media[0]) === 'video' && (
                                                                    <img src={VideoSvg} alt="Video_Placeholder" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.media && record.media[0]) === 'audio' && (
                                                                    <img src={AudioSvg} alt="Audio_Placeholder" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {getMediaType(record.media && record.media[0]) === 'unknown' && (
                                                                    <img src={Unknown} alt="Unknown_Placeholder" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }} />
                                                                )}
                                                                {/* Render actual first image */}
                                                                {getMediaType(record.media && record.media[0]) === 'image' && (<img
                                                                    alt='Product Icon'
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        borderRadius: "4px",
                                                                        objectFit: "fill",
                                                                        overflow: "hidden",
                                                                        marginRight: "10px"
                                                                    }}
                                                                    src={`${config.baseImageUrl}${record.media[0]}`}
                                                                />)}
                                                            </div>
                                                        )}

                                                    </div>
                                                )}
                                            </div>
                                            <div className='row-ellipsis'>
                                                <div
                                                    className='col-ellipsis'
                                                    style={{
                                                        fontSize: 14,
                                                        marginBottom: 4
                                                    }}>{getL10N(record, "title", null).i10n}</div>
                                                <div style={{
                                                    color: "#606060",
                                                    fontSize: 12
                                                }}>{getL10N(record, "subtitle", null).i10n}</div>
                                                <div style={{
                                                    color: "#606060",
                                                    fontSize: 12
                                                }}>{record.options && onGenerateVariant(record.options)}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className="category" style={{ wordWrap: 'break-word' }}>{record.sku}</div>
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                color: vReserved === 0 ? '#C70039' : '#121212',
                                                fontWeight: vReserved === 0 ? 500 : 'normal'
                                            }}>
                                            {vReserved}
                                        </div>
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                color: vCommitted === 0 ? '#C70039' : '#121212',
                                                fontWeight: vCommitted === 0 ? 500 : 'normal'
                                            }}>
                                            {vCommitted}
                                        </div>
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        <motion.div
                                            key={record.variantId}
                                            layout
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            {availableStockId !== record.variantId ? <div
                                                style={{
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                    color: vAvailableStock === 0 ? '#C70039' : '#121212',
                                                    fontWeight: vAvailableStock === 0 ? 500 : 'normal'
                                                }}
                                                onClick={() => {
                                                    // setCommitted(null);
                                                    // setCommittedId(null);
                                                    // setReserved(null);
                                                    // setReservedId(null);
                                                    // setOnHand(null);
                                                    // setOnHandId(null);
                                                    setAvailableStock(null);
                                                    setAvailableStockId(null);
                                                    setAvailableStock(vAvailableStock);
                                                    setAvailableStockId(record.variantId);
                                                }}>
                                                {vAvailableStock}
                                            </div> :
                                                <input type="number" style={{ textAlign: "center" }} ref={availableStockRef} value={availableStock}
                                                    onBlur={() => {
                                                        onUpdateVariantDetails('available', parseInt(availableStock), availableStockId);
                                                        setAvailableStockId(null);
                                                        setAvailableStock(null);
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === "Enter") {
                                                            e.target.blur();
                                                        } else if (e.key === "Escape") {
                                                            setAvailableStockId(null);
                                                            setAvailableStock(null);
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (inputValue === "" || /^\d*\.?\d*$/.test(inputValue)) {
                                                            setAvailableStock(parseInt(inputValue))
                                                        }
                                                    }} />
                                            }
                                        </motion.div>
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                color: vOnHand === 0 ? '#C70039' : '#121212',
                                                fontWeight: vOnHand === 0 ? 500 : 'normal'
                                            }}>
                                            {vOnHand === 0 ? 'Out of Stock' : vOnHand}
                                        </div>
                                    </Col>
                                    {/* <Col span={2} style={{ textAlign: 'center' }}>
                                        <img src={vIsVisible ? VisibilityOnSvg : VisibilityOffSvg} alt="visibility" onClick={() => onUpdateVariantDetails('isVisible', !vIsVisible, record.variantId)} />
                                    </Col> */}
                                </Row>
                            })}
                        </Col>
                    </Row>
                </div>
                <div className="footer">
                    You've got {productVariants && productVariants.length} product variants listed
                </div>
            </Col>}
            {(!outlets || outlets.length === 0) && <Col span={24}> <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column' }}>
                <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Add Products to Outlet</div>
                {/* <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first FAQ.</div> */}
                <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Outlet created yet...</div>
                <div style={{ display: 'flex' }}>
                    <button onClick={() => { props.selectedMainMenu('outlets'); navigateTo(`/${selectedStore.name}/settings/store/outlet`); }} className="sp_btn" style={{ width: 140 }} >+ Create Outlet</button>
                </div>
            </div></Col>}
        </Row >
    )
    // }
    // }

}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getListedProductVariants,
        updateInventory,
        onInventoryFilterOptions,
        selectedMainMenu
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ProductInventory)
